import { isKeyInType } from '@autone/ui';
import { fISOToCalendarDate, fNumber, fPercent } from '@autone/utils';

import { DATE_KEYS, NA } from '../../../config/consts';
import { CurrentCompareMetrics, RetailPerformanceData } from '../../../types';

type DataRow = CurrentCompareMetrics | RetailPerformanceData;

const getValueAsNumber = (data: DataRow, key: string) => {
  const value = isKeyInType(data, key) ? Number(data[key]) : 0;

  if (Number.isNaN(value)) return 0;

  return value;
};

export const current = (dataRow: DataRow, dataKey: string) => {
  return getValueAsNumber(dataRow, dataKey);
};

//TODO: rename to diff or similar once the app is in typescript (same with the other functions in this file)
export const compare = (dataRow: DataRow, a: string, b: string) => {
  const current = getValueAsNumber(dataRow, b);
  const comparison = getValueAsNumber(dataRow, a);

  return current - comparison;
};

/**
 * Returns the formatted percentage difference between the current and previous values.
 * This function is not needed as an input to any other calculations.
 */
export const comparePercent = (
  dataRow: DataRow,
  type: string,
  totalSum = false,
) => {
  const current = getValueAsNumber(dataRow, type);
  const previousKey = totalSum ? `${type}-previous` : `${type}-compare`;
  const compare = getValueAsNumber(dataRow, previousKey);
  const value = Math.abs(1 - current / compare);

  if (Number.isNaN(value) || !Number.isFinite(value)) return NA;
  return `${current > compare ? '+' : '-'}${fPercent(value)}`;
};

export const comparePercentExplorer = (
  dataRow: DataRow,
  currentKey: string,
  compareKey: string,
) => {
  const current = getValueAsNumber(dataRow, currentKey);
  const compare = getValueAsNumber(dataRow, compareKey);
  const value = Math.abs(1 - current / compare);

  if (Number.isNaN(value) || !Number.isFinite(value)) return NA;
  return `${current > compare ? '+' : '-'}${fPercent(value)}`;
};

export const mixCurrent = (
  dataRow: DataRow,
  dataKey: string,
  totals: DataRow,
) => {
  const current = getValueAsNumber(dataRow, dataKey);
  const total = getValueAsNumber(totals, dataKey);
  return fPercent(current / total);
};

export const mixCompare = (
  dataRow: DataRow,
  dataKey: string,
  type: string,
  totals: DataRow,
) => {
  const current = getValueAsNumber(dataRow, type);
  const compare = getValueAsNumber(dataRow, dataKey);
  const currentTotal = getValueAsNumber(totals, type);
  const prevTotal = getValueAsNumber(totals, `${type}-previous`);

  const currentMix = current && totals && (current / currentTotal) * 100;
  const compareMix = compare && totals && (compare / prevTotal) * 100;

  return fNumber(currentMix - compareMix);
};

export const formatDateKeys = (
  item: Record<string, unknown>,
): Record<string, unknown> => {
  const itemKeys = Object.keys(item);

  return itemKeys.reduce((acc: Record<string, unknown>, key: string) => {
    if (DATE_KEYS.includes(key)) {
      acc[key] = fISOToCalendarDate(item[key] as string);
    } else {
      acc[key] = item[key];
    }
    return acc;
  }, {});
};
