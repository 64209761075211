import {
  DataItem,
  DataKey,
  exportElementToJpg,
  INSIGHTS_PATH_APP,
  isKeyInType,
  LightTooltip,
  TableBody,
  TableCellsWrapper,
  TableConfigType,
  TableFooter,
  TableGroup,
  TableHeader,
  TableHeaderCell,
  TablePropsType,
} from '@autone/ui';
import { capitalizeFirstLetter } from '@autone/utils';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { IconButton, Stack } from '@mui/material';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useLocation } from 'react-router-dom';

import {
  compareKeys,
  mixCompareKeys,
  mixCurrentKeys,
  percentageKeys,
} from '../config';
//@ts-ignore TODO: change when CalcType is typed
import CalcType from '../features/CalcType';
import { useDataExport } from '../hooks';
import store, { useAppSelector as useSelector } from '../redux/store';
import {
  CubeQuery,
  ExplorerPerformanceData,
  RetailPerformanceData,
} from '../types';
import { getTableTotals } from '../utils';
import { Mixpanel } from '../utils/mixpanel';
import { FULL_SCREEN } from '../utils/mixpanel/eventTypes';

import AutoneTableFooter from './AutoneTableFooter';

type AutoneTableProps = {
  tableData?: (RetailPerformanceData | ExplorerPerformanceData)[];
  cubeQuery?: CubeQuery[];
  tableConfig: TableConfigType<{
    dataRow: RetailPerformanceData | ExplorerPerformanceData;
  }>[];
  tableProps: TablePropsType;
  title: string;
  maxHeight?: string;
  name?: string;
  isLoading: boolean;
  handleSort: (column?: string | undefined, key?: string | undefined) => void;
  handleTableSelectionsCallback: (
    dataItem: DataItem,
    dataKey: DataKey,
    tableConfig: TableConfigType<{
      dataRow: RetailPerformanceData | ExplorerPerformanceData;
    }>,
    cubeQuery?: CubeQuery[],
  ) => void;
  handleChangeCallback: (value: string) => void;
  selectedMetric: string;
  showCalcChangeButton: boolean;
  footer: boolean;
  id: string | number;
  noResultComponent: React.ReactNode;
  enableCustomColumns: boolean;
  pagination: boolean;
};

const AutoneTable = ({
  tableData,
  cubeQuery,
  tableConfig,
  tableProps,
  title,
  maxHeight,
  name,
  isLoading,
  handleSort,
  handleTableSelectionsCallback,
  handleChangeCallback,
  selectedMetric,
  showCalcChangeButton,
  footer,
  id,
  noResultComponent,
  enableCustomColumns,
  pagination,
}: AutoneTableProps) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { selectedCurrency } = useSelector((state) => state.priceOptions);
  const { calculationConfig } = store.getState().config;
  const calcType = isKeyInType(calculationConfig, name)
    ? calculationConfig[name]
    : null;
  const { pathname } = useLocation();

  const headerList =
    tableConfig &&
    tableConfig.map(({ dataKey, header, drilldown }) => {
      // Add checks for drilldown dimensions
      const drillHeader = drilldown
        ? cubeQuery && cubeQuery[0]?.drilldownHeader
        : header;

      const drillKey = drilldown
        ? cubeQuery && cubeQuery[0]?.dimensionName?.[0]
        : dataKey;

      return {
        key: drillKey,
        label: drillHeader,
      };
    });

  // get totals
  const totals = useMemo(
    () => tableData && getTableTotals(tableData),
    [tableData],
  );

  const { csvData, csvHeader, createFormattedCsVData } = useDataExport({
    dataToExport: tableData,
    headerList: headerList || [],
    calcType,
    totals,
    mixCurrentKeys: mixCurrentKeys(calcType),
    mixCompareKeys: mixCompareKeys(calcType),
    compareKeys,
    percentageKeys,
  });

  const handleFullScreenCallback = (fullScreen: boolean) => {
    setIsFullScreen(fullScreen);
    Mixpanel.track(FULL_SCREEN, { table: name });
  };

  const exportData = useMemo(
    () => createFormattedCsVData(csvData, csvHeader),
    [csvData, csvHeader],
  );

  return (
    <div id={id.toString()}>
      <TableGroup
        size="small"
        tableProps={tableProps}
        data={isLoading ? undefined : tableData}
        title={title}
        enableFullScreen
        fullScreenCallback={handleFullScreenCallback}
        fullScreenHeight={'calc(100vh - 80px)'}
        gridMarginTop="0px"
        tableContainerSx={{ maxHeight: maxHeight || '500px' }}
        enableCustomColumns={enableCustomColumns}
        pagination={pagination}
        noResultComponent={noResultComponent}
        customRowFormat={() => ({
          '&:hover': {
            cursor: 'pointer',
          },
        })}
        actionButtonsSpacing={0.5}
        headerButton={
          <Stack direction="row" alignItems="center" spacing={2}>
            {showCalcChangeButton && (
              <CalcType
                isFullScreen={isFullScreen}
                calcType={calcType}
                handleChangeCallback={handleChangeCallback}
                sx={{ mr: 2 }}
              />
            )}
            <Stack direction="row" alignItems="center" spacing={0.5}>
              {csvData && (
                <CSVLink
                  data={exportData}
                  style={{ textDecoration: 'none' }}
                  filename={`${title} ${moment().format(
                    'YYYY-MM-DD-HH-mm-ss',
                  )}.csv`}
                >
                  <LightTooltip title="export data">
                    <IconButton>
                      <FileDownloadOutlinedIcon />
                    </IconButton>
                  </LightTooltip>
                </CSVLink>
              )}
              <LightTooltip title="take screenshot">
                <IconButton onClick={() => exportElementToJpg(id)}>
                  <CameraAltOutlinedIcon />
                </IconButton>
              </LightTooltip>
            </Stack>
          </Stack>
        }
      >
        <TableHeader>
          {tableConfig &&
            tableConfig
              .filter((d) => d.active)
              .map((d) => (
                <TableHeaderCell
                  key={d.id}
                  dataKey={d.dataKey}
                  align={d.headerAlign}
                  tooltip={d.tooltip}
                  onClick={() =>
                    handleSort(
                      d.drilldown && cubeQuery
                        ? cubeQuery[0]?.dimensionName &&
                            cubeQuery[0]?.dimensionName?.[0]
                        : d.dataKey,
                    )
                  }
                >
                  {pathname === INSIGHTS_PATH_APP.explorer
                    ? capitalizeFirstLetter(
                        d.drilldown
                          ? cubeQuery && cubeQuery[0]?.drilldownHeader
                          : d.header,
                      )
                    : d.drilldown
                    ? cubeQuery && cubeQuery[0]?.drilldownHeader
                    : d.header}
                </TableHeaderCell>
              ))}
        </TableHeader>
        <TableBody>
          {tableConfig &&
            tableConfig
              .filter((d) => d.active)
              .map((d) => (
                <TableCellsWrapper
                  key={d.id}
                  tableConfig={d}
                  align={d.cellAlign}
                  metric={selectedMetric}
                  cubeQuery={cubeQuery}
                  totals={totals}
                  selectedCurrency={selectedCurrency}
                  handleClickCallback={(row, key) =>
                    handleTableSelectionsCallback(row, key, d, cubeQuery)
                  }
                />
              ))}
        </TableBody>
        {footer && (
          <TableFooter>
            <AutoneTableFooter
              isLoading={isLoading}
              data={totals}
              metric={selectedMetric}
              tableConfig={tableConfig}
              name={name}
            />
          </TableFooter>
        )}
      </TableGroup>
    </div>
  );
};

export default AutoneTable;
